import React, {useState,useEffect} from 'react'
import { StaticImage } from "gatsby-plugin-image"
import './navbar.css';
import { Link,graphql } from "gatsby";
import { FaAlignLeft } from 'react-icons/fa';
import * as data from '../constant/data.js';
import { Helmet } from "react-helmet";
//logo scroll when active
import logo from "./logo.png"
import logoBlack from "./logoBlack.png"

function Navbar() {
    const [toggle,setToggle]=useState(false);
    const [backgroundColor,setBackgroundColor] = useState(false);
    const [navbarLogo, setNavbarLogo] = useState(logo);
    const toggleHandler=()=>{
        setToggle(!toggle);
    };
const changeNavbar=(state)=>{
// this is to add something more if needed
setBackgroundColor(state);
if(state){
    setNavbarLogo(logoBlack)
  
}else{
    setNavbarLogo(logo)
}

}
    const changeBackground=()=>{
        if (typeof window !== `undefined`) {
            console.log(window.scrolllY);
            if (window.scrollY>=66) {
                changeNavbar(true);
            }else{
                changeNavbar(false);
            }
        }
        
    }
    useEffect(()=>{
      changeBackground()
       // adding event when scroll
       window.addEventListener("scroll", changeBackground)
    },[])


    return (

    <nav className={`navbar ${backgroundColor?"sticky":null}`}>
    <Helmet>
          
          <meta charSet="utf-8" />
          <title>The Brand Digest</title>
          <link rel="canonical" href="https://thebranddigest.com" />
          {/* <link rel="canonical" href={data.site.siteMetadata.url} /> */}
          <meta name="image" content="/seoHeader.png" />
          <meta property="og:description" content="At The Brand Digest we create luxury experiences and brands through creative storytelling, innovative thinking and services. We assist you on your path towards realizing your vision. Whether it’s building a catalogue, devising a business strategy, conceptualizing a brand shoot, composing an eloquent brand story or designing a communication plan, our aim is to give your brand an identity and a voice." />
        </Helmet>
     <Link to="/"><img  className='logo' src={navbarLogo} alt="The Brand Digest" /></Link>
     <div className="menu-icon">
     <button  onClick={()=>toggleHandler()} >
        <FaAlignLeft color={backgroundColor && !toggle?"black":"white"} />
                    </button>

     </div>
    
     
     <ul className={toggle?'nav-container show-nav':'nav-container' }> 
     <li className="nav-items"> <Link to='/'> Home </Link></li>
        <li className="nav-items"><Link to='/about'> About Us</Link></li>
        <li className="nav-items"> <Link to="/services">Services</Link></li>
        <li className="nav-items"> <Link to="/blogs">Blogs</Link></li>
       
        <li className="nav-items"><Link to="/brand-of-the-month"> #BrandOfTheMonth </Link></li>
        <li className="nav-items"><Link to="/case-studies"> Case Studies </Link></li>
        <li className="nav-items"><Link to="/consultation"> Consulations</Link></li>
        <div className="socialLinksMenu">
        <div className="contact-email"><a href={`mailto:${data.buisnessEmail}`}>{data.buisnessEmail}</a></div>
       
<div className="social-links-container"> 
<a  href={data.linkedinUrl} className="socail-link"><StaticImage  layout="fixed" width={20} height={20} src="../Footer/linkedin.png" alt="Linkedin" /></a>
<a  href={data.instagramUrl} className="socail-link"><StaticImage layout="fixed" width={20} height={20} src="../Footer/instagram.png" alt="Instagram" /></a>
<a href={data.facebookUrl}  className="socail-link"><StaticImage layout="fixed" width={20} height={20} src="../Footer/facebook.png" alt="facebook" /></a>
<a  href={data.mediumUrl} className="socail-link"><StaticImage layout="fixed" width={20} height={20} src="../Footer/medium.png" alt="medium" /></a>
</div></div>
        </ul>
       
        </nav>


    );
}
export default Navbar;
// export const query=graphql `
// query  {
//   site {
//     siteMetadata {
//       image
//       description
//       siteUrl
//       title
//       url
//     }
//   }
// }

// `