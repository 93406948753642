import React from 'react'
import {Link} from 'gatsby';
import {navigate} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
 import './consultation-banner.css'
function CheckServices({text, link}) {
    const navigateHandler=()=>{
        navigate(link);
    }
    return (
        <div className="check-services" onClick={()=>navigateHandler()} > 
        <div className="check-services-text">
            <h4 style={{marginLeft:"1.2em"}} >{text}</h4>
            <StaticImage style={{height:"50%"}} imgStyle={{objectFit:'contain'}} style={{margin:"3px 10px 3px 10px"}}src="./arrow-grey.png" alt="->"/>
            
        </div>
        <div style={{margin:"0vw 2vw",height:"2px",width:"40%", backgroundColor:"#707070"}}></div></div>
    )
}
export default CheckServices;