import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
// import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
// import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import '../styles/index.css'
import { StaticImage } from "gatsby-plugin-image";
import CheckServices from '../components/CheckServices';
import ConsultationBanner from '../components/ConsultationBanner';
// import Footer from '../components/Footer/footer';
import Navbar from '../components/Navbar/Navbar'
export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
   
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const projectNodes = (data || {}).blogs
    ? mapEdgesToNodes(data.blogs)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
        <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Navbar/>
    <div className='home'>
    
    <StaticImage className='banner-image' src="./images/Homepage.png" alt="Homepage" />
    
    <div className='main-heading'>
      <h1>The</h1>
      <h1>Brand Digest</h1>
      <div className="sub-heading">
      <h2>Luxury branding</h2>
      <h2>Lifestyle</h2>
      <h2>Consultations</h2>
    </div>
    </div>
   
    </div>
     <div className='welcome'>
<div className='welcome-content'> 
<div className='welcome-heading'>Welcome To</div>
<div className='brand-name'>The Brand digest</div>
<div className="brand-moto">An independent luxury and brand consultancy with a passion for everything luxé!
</div>
<p> For as long as we can remember, our hearts have always been in the sky, floating around amidst the clouds. We’re something like the romantics and dreamers at heart. And maybe that’s why we’re fools for brands too. The business of brands is just as much about stories as it is about products and services</p>
<p>So here we are, a team of creatives and brand nerds at this crazy place called The Brand Digest, to help you build the brands of your dreams. We are here to help you build a brand that is not only a business ninja but also a master storyteller. Together, we will bring your dreams to reality.</p>
  <div className='passion'> 
  <StaticImage className='passion-image' src="./images/passion.png" alt="Our passion lead us here" />
  <div className="passion-quote"> <p>"Our passion lies not in the products but in the businesses of the brands that sell these products. We don’t simply want to sell products, we want to help you build brands that create emotions and associations by way of selling products."</p></div>
   </div></div>
   
   <div className="grey-line">
</div>
<div className="our-method">
  <div className="our-method-content">
  <h2> Our Method</h2>
  <p> At The Brand Digest we create luxury experiences and brands through creative storytelling, innovative thinking and services. We assist you on your path towards realizing your vision. Whether it’s building a catalogue, devising a business strategy, conceptualizing a brand shoot, composing an eloquent brand story or designing a communication plan, our aim is to give your brand an identity and a voice.</p>
  <p>We do not believe in throwing a bunch of questions at you to answer. Instead, we focus on discovering these questions together with you and accompany you on your path to find the answers. We follow a holistic, authentic and a 100% individualistic approach to your branding needs focused on culture and purpose. Because when you stay true to yourself and focus on what’s important, you’re bound to discover success!</p>
 
  <CheckServices text="Check Services" link="/services"/>
  </div>

  <StaticImage className="our-method-image" src="./images/our-method.png" alt="our method"></StaticImage>
   

</div>
</div>
<ConsultationBanner/>

  
    </Layout>
  );
};

export default IndexPage;
