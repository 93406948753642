import React from 'react'
import CheckServices from './CheckServices';
import { StaticImage } from "gatsby-plugin-image";
import "./consultation-banner.css";
function ConsultationBanner(params) {
    return(
        <div className="consultation-banner"> 

            <StaticImage style={{width:'100%',height:'100%'}} imgStyle={{width:'100%',height:'100%',objectFit:'cover'}} src='./backdrop.png' alt="Book Consultation"/> 
            <div style={{position:'absolute', top:'0', left:'0'}}>
                <h2 className="banner-heading" > LET’S GET TO KNOW YOUR BRAND!</h2>

                <div>
                    <p>Interested in working with us? Fantastic.
We are a small team of dedicated creatives who’re enchanted by everything luxurious. We only work with brands that share our beliefs and values. That is why we begin our journey together with a free consultation session where we get to know you and your brand. Please fill out the form below with your preferred schedule and we will revert back with a possible appointment.
</p>
 <CheckServices text="Book Consulation" link="/consultation"></CheckServices>
                </div>

            </div>
        </div>
        )
}

export default  ConsultationBanner;